<template>
  <div class="card">
    <div class="card-body">
      <cron-light
        ref="cronLight"
        v-model="value"
        @error="error = $event"
      ></cron-light>
      <input type="text" class="form-control mt-2" :value="value" required />
    </div>
  </div>
</template>
<script>
export default {
  name: "NCronTabGenerator",
  props: {
    init: {
      type: String,
      default: "* * * * *",
    },
  },
  data() {
    return {
      value: "",
      error: "",
    };
  },
  mounted() {
    this.value = this.init;
  },
};
</script>
