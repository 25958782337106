<template>
  <div class="d-flex justify-content-between new-block">
    <div>
      <h2 class="float-start">
        {{
          $t(
            "BusinessRule.NewBusinessRule",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </h2>
    </div>
    <div>
      <ActionButtons
        @methodSave="onSubmit('0', 'btn-save')"
        @methodSaveAndNew="onSubmit('1', 'btn-save-and-new')"
        @clear="onClear()"
        :isNewRecord="true"
      />
    </div>
  </div>
  <form class="form-businessrule-new pe-2 ps-2" novalidate>
    <div v-if="errors.length > 0" class="alert alert-warning">
      <ul class="mb-0">
        <li v-for="error in errors" v-bind:key="error">
          {{ error }}
        </li>
      </ul>
    </div>
    <div v-if="isSuccess" class="alert alert-success mt-2 mb-0">
      {{
        $t(
          "Messages.SuccessMessage",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}
    </div>
    <ul class="nav nav-tabs mb-2 mt-0" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="pills-general-tab"
          @click="tabValidation"
          data-bs-toggle="pill"
          data-bs-target="#pills-general"
          type="button"
          role="tab"
          aria-selected="true"
        >
          <i class="bi bi-gear-fill"></i>
          {{
            $t(
              "BusinessRule.GeneralSettings",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </button>
      </li>
      <li
        class="nav-item"
        role="presentation"
        v-if="this.model.businessRuleTriggerTypeId == 5"
      >
        <button
          v-if="
            !String.isNullOrWhiteSpace(this.model.name) &&
            !String.isNullOrWhiteSpace(this.model.formulaName)
          "
          @click="tabValidation"
          class="nav-link"
          id="pills-cron-generator-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-cron-generator"
          type="button"
          role="tab"
          aria-selected="false"
        >
          <i class="bi bi-box"></i>
          {{
            $t(
              "Components.CronGenerator.Title",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </button>
        <button type="button" class="nav-link" @click="tabValidation" v-else>
          <i class="bi bi-box"></i>
          {{
            $t(
              "Components.CronGenerator.Title",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </button>
      </li>
      <li class="nav-item" role="presentation" v-else>
        <button
          v-if="
            !String.isNullOrWhiteSpace(this.model.name) &&
            !String.isNullOrWhiteSpace(this.model.formulaName)
          "
          @click="tabValidation"
          class="nav-link"
          id="pills-criteria-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-criteria"
          type="button"
          role="tab"
          aria-selected="false"
        >
          <i class="bi bi-funnel-fill"></i>
          {{
            $t(
              "BusinessRule.Criterias",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </button>
        <button type="button" class="nav-link" @click="tabValidation" v-else>
          <i class="bi bi-funnel-fill"></i>
          {{
            $t(
              "BusinessRule.Criterias",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </button>
      </li>
    </ul>
    <div class="tab-content">
      <div class="tab-pane fade active show" id="pills-general" role="tabpanel">
        <div class="row row-cols-1">
          <div class="col col-md-4">
            <label for="Name" class="form-label required"
              >{{
                $t(
                  "BusinessRule.Name",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </label>
            <input
              type="text"
              class="form-control"
              @keyup="nameKeyup()"
              id="Name"
              v-model="model.name"
              :autocomplete="this.$isAutoComplete"
              :spellcheck="this.$isTextSpellCheck"
              required
            />
          </div>
          <div class="col col-md-4">
            <label for="FormulaName" class="form-label required">{{
              $t(
                "BusinessRule.FormulaName",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}</label>
            <input
              type="text"
              class="form-control text-uppercase"
              id="FormulaName"
              @keyup="formulaNameKeyup()"
              v-model="model.formulaName"
              :autocomplete="this.$isAutoComplete"
              :spellcheck="this.$isTextSpellCheck"
              required
            />
          </div>
          <div class="col col-md-4">
            <label for="businessProcess" class="form-label">{{
              $t(
                "BusinessRule.BusinessProcess",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}</label>
            <FormSelect
              type="SelectWithRemoteDataAndInternalSearch"
              :selectedData="selectedBusinessProcess"
              :data="businessProcessData"
              requestUrl="/Brs-BusinessRuleBusinessProcess"
              :isGatewayRequest="true"
              @onChange="onChangeForBusinessProcess"
            />
          </div>
        </div>
        <div class="row row-cols-1 mt-3">
          <div class="col col-md-12">
            <label for="BusinessRuleTrigger" class="form-label required">{{
              $t(
                "BusinessRule.BusinessRuleTriggerType",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}</label>
            <FormSelect
              type="SelectWithLocalData"
              :selectedData="selectedBusinessRuleTrigger"
              :allowEmpty="false"
              :data="businessRuleTriggerData"
              @onChange="onChangeForBusinessRuleTriggerType"
            />
          </div>
        </div>
        <div class="row row-cols-1 mt-3">
          <div class="col col-md-12">
            <div class="form-check">
              <label for="IsNotShowResultToClient" class="form-label">{{
                $t(
                  "BusinessRule.IsNotShowResultToClient",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <input
                class="form-check-input"
                type="checkbox"
                id="IsNotShowResultToClient"
                v-model="model.isNotShowResultToClient"
              />
            </div>
          </div>
        </div>
        <div class="row row-cols-1 mt-3">
          <div class="col col-md-12">
            <label for="Description" class="form-label">{{
              $t(
                "BusinessRule.Description",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}</label>
            <textarea
              name="Description"
              class="form-control"
              id="Description"
              rows="5"
              v-model="model.description"
              :spellcheck="this.$isTextSpellCheck"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="pills-cron-generator" role="tabpanel">
        <CronGenerator ref="cronGenerator" />
      </div>
      <div class="tab-pane fade" id="pills-criteria" role="tabpanel">
        <div class="row row-cols-1">
          <div class="col col-md-6">
            <label for="CriteriaExplanation" class="form-label"
              >{{
                $t(
                  "BusinessRule.CriteriaExplanation",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </label>
            <input
              type="text"
              class="form-control"
              id="CriteriaExplanation"
              v-model="model.criteriaExplanation"
              :autocomplete="this.$isAutoComplete"
              :spellcheck="this.$isTextSpellCheck"
            />
          </div>
          <div class="col col-md-6">
            <label for="FirstRunIntervalInSeconds" class="form-label required"
              >{{
                $t(
                  "BusinessRule.FirstRunIntervalInSeconds",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </label>
            <input
              type="text"
              class="form-control"
              id="FirstRunIntervalInSeconds"
              v-model="model.firstRunIntervalInSeconds"
              :allowEmpty="false"
              :autocomplete="this.$isAutoComplete"
              :spellcheck="this.$isTextSpellCheck"
              required
            />
          </div>
        </div>
        <div class="row row-cols-1">
          <div class="col col-md-6">
            <label for="RepeatIntervalInSeconds" class="form-label required"
              >{{
                $t(
                  "BusinessRule.RepeatIntervalInSeconds",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </label>
            <input
              type="text"
              class="form-control"
              id="RepeatIntervalInSeconds"
              v-model="model.repeatIntervalInSeconds"
              :allowEmpty="false"
              :autocomplete="this.$isAutoComplete"
              :spellcheck="this.$isTextSpellCheck"
              required
            />
          </div>
          <div class="col col-md-6">
            <label for="RepeatCount" class="form-label required"
              >{{
                $t(
                  "BusinessRule.RepeatCount",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </label>
            <input
              type="text"
              class="form-control"
              id="RepeatCount"
              v-model="model.repeatCount"
              :allowEmpty="false"
              :autocomplete="this.$isAutoComplete"
              :spellcheck="this.$isTextSpellCheck"
              required
            />
          </div>
        </div>
        <div class="col-12 mt-3">
          <div class="form-check">
            <label for="IsExecuteForever" class="form-label">{{
              $t(
                "BusinessRule.IsExecuteForever",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}</label>
            <input
              class="form-check-input"
              type="checkbox"
              id="IsExecuteForever"
              v-model="model.isExecuteForever"
            />
          </div>
        </div>
        <div class="col-12 mb-3">
          <label for="Validation" class="form-label"
            >{{
              $t(
                "BusinessRule.Validation",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
            <a
              @click="openFormulaEditor"
              class="cursor-pointer text-decoration-none text-primary ms-1"
            >
              <span
                ><img
                  width="20"
                  height="20"
                  :src="
                    require('@/assets/images/icons/function-primary.png')
                  " /></span
              >&nbsp;
              {{
                $t(
                  "Components.FormulaEditorModal.OpenFormulaEditor",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</a
            ></label
          >
          <textarea
            name="Validation"
            class="form-control"
            id="Validation"
            :spellcheck="this.$isTextSpellCheck"
            v-model="model.validation"
            rows="5"
          ></textarea>
        </div>
        <QueryBuilder ref="sxQuerybuilder" :isCriteria="true" />
      </div>
    </div>
    <ActionButtons
      v-if="$isMobile()"
      classes="mt-3"
      @methodSave="onSubmit('0', 'btn-save')"
      @methodSaveAndNew="onSubmit('1', 'btn-save-and-new')"
      @clear="onClear()"
      :isNewRecord="true"
    />
  </form>
  <FormulaEditorModal
    :formulaField="formulaField"
    parentRecordPrefix=""
    @updateFormula="updateFormula"
  />
</template>
<script>
import "@/assets/css/fa-all.css";
import $ from "jquery";
import { showModal } from "@/core/helpers/dom";
import CronGenerator from "@/components/custom/cron-generator/NCronTabGenerator.vue";
import FormulaEditorModal from "@/components/custom/formula-editor/FormulaEditorModal.vue";
export default {
  name: "CustomObjectNewBusinessRule",
  components: {
    CronGenerator,
    FormulaEditorModal,
  },
  data() {
    return {
      formulaField: {},
      businessRuleTriggerData: [
        {
          key: 1,
          value: this.$t(
            "BusinessRule.RecordCreated",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "BusinessRule.RecordUpdated",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 3,
          value: this.$t(
            "BusinessRule.RecordCreatedOrUpdated",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 4,
          value: this.$t(
            "BusinessRule.RecordDeleted",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 5,
          value: this.$t(
            "BusinessRule.TimeTriggered",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 6,
          value: this.$t(
            "BusinessRule.UserTriggered",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectedBusinessRuleTrigger: [
        {
          key: 1,
          value: this.$t(
            "BusinessRule.RecordCreated",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      businessProcessData: [],
      selectedBusinessProcess: [],
      model: {
        name: "",
        description: "",
        isActive: true,
        isNotShowResultToClient: false,
        customObjectPublicId: this.$route.params.customObjectId,
        customObjectFormulaName:
          (this.$store.state.customObjectDto &&
            this.$store.state.customObjectDto.formulaName) ||
          null,
        customObjectName: "",
        customObjectKeyName: "",
        businessProcessId: "",
        businessProcess: "",
        criterias: [],
        criteriaExpression: "",
        cronExpression: "",
        isExecuteForever: false,
        firstRunIntervalInSeconds: 1,
        repeatIntervalInSeconds: 1,
        repeatCount: 1,
        criteriaExplanation: "",
        businessRuleTriggerType: this.$t(
          "BusinessRule.RecordCreated",
          {},
          { locale: this.$store.state.activeLang }
        ),
        businessRuleTriggerTypeId: 1,
      },
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save, .btn-save-and-new, .btn-clear",
      errors: [],
      criteriaExpressionErrors: [],
      columnTabActive: false,
      isSuccess: false,

      viewFilterViewableFields: [],
      selectedFields: [],

      selectedOrderFields: [],
      orderFields: [],
      orderField: "",
      orderFieldText: "",
      orderType: "",

      selectedButtons: [],
      button: "",
      buttonName: "",
      buttons: [],
      allButtons: [],

      multipleSelectValueReset: false,
      selectButtonReset: false,
      hiddenFields: [],

      query: "",
    };
  },
  methods: {
    openFormulaEditor() {
      this.formulaField = {
        formula: this.model.validation,
        customObjectPublicId: this.model.customObjectPublicId,
      };
      showModal(document.getElementById("modalFormulaEditor"));
    },
    updateFormula(validation) {
      this.model.validation = validation;
    },
    nameKeyup() {
      if (String.isNullOrWhiteSpace(this.model.name)) {
        this.model.formulaName = "";
        return;
      }
      this.model.formulaName = String.replaceAlphanumeric(
        this.model.name,
        "_"
      ).toUpperCase();
    },
    formulaNameKeyup() {
      this.model.formulaName = String.replaceAlphanumeric(
        this.model.formulaName,
        "_"
      ).toUpperCase();
    },
    onChangeForBusinessProcess: function (selected) {
      this.model.businessProcessId = selected.key;
      this.model.businessProcess = selected.value;
    },
    onChangeForBusinessRuleTriggerType: function (selected) {
      this.model.businessRuleTriggerType = selected.value;
      this.model.businessRuleTriggerTypeId = selected.key;
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
    tabValidation($event) {
      var form = $(".form-businessrule-new");
      form.addClass("was-validated");
      if (form[0].checkValidity()) {
        form.removeClass("was-validated");
        this.columnTabActive = $event.target.id.includes("column");
      }
    },
    onClear() {
      $("#pills-general-tab").trigger("click");
      this.model = {
        redirectUrl: "",
        publicId: "",
        name: "",
        description: "",
        isActive: true,
        companyId: 0,
        createdByName: "",
        updatedByName: "",
        updatedBy: 0,
        createdBy: 0,
        customObjectPublicId: this.model.customObjectPublicId,
        customObjectName: "",
        customObjectKeyName: "",
        businessProcessId: "",
        isExecuteForever: false,
        firstRunIntervalInSeconds: null,
        repeatIntervalInSeconds: null,
        repeatCount: null,
        cronExpression: "",
        criteriaExplanation: "",
        criterias: [],
        criteriaExpression: "",
        businessRuleTriggerType: "",
        businessRuleTriggerTypeId: 1,
        customObjectFormulaName: this.model.customObjectFormulaName,
      };
      this.selectedButtons = [];

      $(".form-businessrule-new").removeClass("was-validated");
    },
    onSubmit(submitType, buttonName) {
      var form = $(".form-businessrule-new");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.model.criterias = this.$refs.sxQuerybuilder.criterias;
      this.model.criteriaExpression = this.$refs.sxQuerybuilder.expression;
      if (this.model.businessRuleTriggerTypeId == 5) {
        this.model.cronExpression = this.$refs.cronGenerator.value;
      }

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Brs-BusinessRuleNew", { ...this.model })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            if (submitType == 0) {
              this.$router.push(
                "/CustomObject/BusinessRules/" +
                  this.$route.params.customObjectId
              );
            } else {
              this.isSuccess = true;
              setTimeout(
                () => (this.isSuccess = false),
                this.$appSaveAndNewMessageDuration
              );
              this.onClear();
              firstSpan.show();
              loadingBlock.hide();
              disabledButtons.prop("disabled", false);
              form.removeClass("was-validated");
            }
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
        });
    },
  },
  watch: {
    "$store.state.customObjectDto"(newValue) {
      if (!String.isNullOrWhiteSpace(newValue)) {
        this.model.customObjectPublicId =
          this.$store.state.customObjectDto.publicId;
        this.model.customObjectFormulaName =
          this.$store.state.customObjectDto.formulaName;
      }
    },
  },
};
</script>
